<template>
  <b-container fluid="xs">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#0F0"/>
    <div v-show="items.length">
    <b-table-simple hover small caption-top responsive="xs">
      <b-thead head-variant="light">
        <b-tr>
          <b-th v-b-tooltip.hover :title="$t('message.msto_ttc')" @click="changesort(0)">{{$t('message.msto_th0')}}<b-icon @click="doupdown(0)" :icon="sort==0?(updown==1?'sort-down':'sort-down-alt'):'chevron-bar-contract'" :variant="sort==0?'danger':'primary'"></b-icon></b-th>
          <b-th v-b-tooltip.hover :title="$t('message.msto_tt1')" @click="changesort(1)">{{$t('message.msto_th1')}}<b-icon @click="doupdown(1)" :icon="sort==1?(updown==1?'sort-down':'sort-down-alt'):'chevron-bar-contract'" :variant="sort==1?'danger':'primary'"></b-icon></b-th>
          <b-th v-b-tooltip.hover :title="$t('message.msto_tt9')">{{$t('message.msto_th9')}}</b-th>
          <b-th v-b-tooltip.hover :title="$t('message.msto_tt2')">{{$t('message.msto_th2')}}</b-th>
          <b-th v-b-tooltip.hover :title="$t('message.msto_tt3')" class="d-none d-sm-table-cell">{{$t('message.msto_th3')}}</b-th>
          <b-th v-b-tooltip.hover :title="$t('message.msto_tt4')" class="d-none d-md-table-cell" @click="exshowid" v-if="showid==0">{{$t('message.msto_th4')}}</b-th>
          <b-th v-b-tooltip.hover :title="$t('message.msto_tt5')" class="d-none d-md-table-cell" v-if="showid==0">{{$t('message.msto_th5')}}</b-th>
          <b-th v-b-tooltip.hover :title="$t('message.msto_tt6')" class="d-none d-md-table-cell" v-if="showid==0">{{$t('message.msto_th6')}}</b-th>
          <b-th v-b-tooltip.hover :title="$t('message.msto_tt7')" class="d-none d-sm-table-cell" v-if="showid==0">{{$t('message.msto_th7')}}</b-th>
          <b-th v-b-tooltip.hover :title="$t('message.msto_tt8')" class="d-none d-sm-table-cell" v-if="showid==0">{{$t('message.msto_th8')}}</b-th>
          <b-th class="d-none d-lg-table-cell" v-if="showid==0">{{$t('message.msto_thd')}}</b-th>
          <b-th v-b-tooltip.hover :title="$t('message.msto_tte')" v-if="showid==1" @click="exshowid">{{$t('message.msto_the')}}</b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-for="(item, index) in items" :key="index">
        <b-tr>
          <b-td v-b-tooltip.hover :title="item.mac">
          <b-button size="sm" variant="outline-info" @click="gotodevice(item)">{{item.chargerid}}</b-button>
          </b-td>
          <b-td :class="item.connected==1?'text-success':'text-secondary'" v-b-tooltip.hover :title="item.connected==1?'第'+item.cot+'次联网: '+item.pot:'离线时间:'+item.ofline">{{item.act}}</b-td>
          <b-td>{{item.pon}}</b-td>
          <b-td>{{item.yyrealname}}
              <b-button size="sm" variant="link" :disabled="((item.siteid>0)||(item.openid==''))" @click="stoclearopenid(item)"><b-icon :variant="((item.siteid>0)||(item.openid==''))?'secondary':'success'" icon="x-circle"></b-icon></b-button>
          </b-td>
          <b-td class="d-none d-sm-table-cell">{{item.sitename}}
            <b-button size="sm" variant="link" :disabled="item.siteid==0" @click="stoclearsiteid(item)"><b-icon :variant="item.siteid==0?'secondary':'success'" icon="x-circle"></b-icon></b-button>
          </b-td>
          <b-td v-if="showid==0" class="d-none d-md-table-cell">{{item.addedtime}}</b-td>
          <b-td v-if="showid==0" class="d-none d-md-table-cell" v-b-tooltip.hover :title="item.cellinfo">{{item.netstr}}</b-td>
          <b-td v-if="showid==0" class="d-none d-md-table-cell">{{item.ver}}</b-td>
          <b-td v-if="showid==0" class="d-none d-sm-table-cell">{{item.ports}}</b-td>
          <b-td v-if="showid==0" class="d-none d-sm-table-cell">
             <b-button size="sm" variant="outline-success" @click="storeqrcode(item)" ><b-icon icon="vr" rotate="90" aria-hidden="true"></b-icon></b-button>
          </b-td>
          <b-td v-if="showid==0" class="d-none d-lg-table-cell">{{resetflags[item.rsf]}}</b-td>
          <b-td v-if="showid==1 && workingid!=index" @click="setme(index)">{{item.imaxstr}}</b-td>
          <b-td v-if="showid==1 && workingid==index">
              <b-input-group size="sm">
                <b-form-input v-for="(ip, index) in item.imax" :key="index" v-model="item.imax[index]" class="" style="width:2.4em;"></b-form-input>
                <b-input-group-append>
                  <b-button variant="info" @click="saveme(item)">保存</b-button>
                </b-input-group-append>
              </b-input-group>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td colspan="5" class="greyco d-table-cell d-sm-none">
            <span class="mr-2">{{item.sitename}}
              <b-button size="sm" variant="link" :disabled="item.siteid==0" @click="stoclearsiteid(item)"><b-icon :variant="item.siteid==0?'secondary':'success'" icon="x-circle"></b-icon></b-button>
            </span>
            <span class="mr-4">{{item.addtime}}</span>
            [{{item.netstr}}] [V{{item.ver}}] [P{{item.ports}}] [{{resetflags[item.rsf]}}]
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </div>
    <div v-show="items.length==0" class="pl-5 pr-5">
      <b-card class="xnshadow" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0"><b-icon icon="clock" variant="success"></b-icon> 采购库存</h4>
        </template>
        <b-card-text>
          <p>
            没有发现满足条件的数据<br/><br/>
            购买设备，扫码添加，创建电站，分配到电站，开始赚钱。<br/>
            默认按电桩编号降序排列，可选择按重启降序排列。<br/>
            请选择其他类型的设备查看数据<br/>
            电桩编号过滤支持模糊查询，比如：起始编号输入1010表示查询101000~101099<br />
            <b-form inline class="mt-4"><b-alert :show="admnodft">{{$t('message.cfmdefault')}}</b-alert></b-form>
           </p>
        </b-card-text>
      </b-card>
    </div>
    <div v-show="maskenabled" class="fullmask" @click="closemask">
      <div class="imgshow xnshadow">
        <img :src="bigheadimgurl" class="w-100"/>
      </div>
    </div>
    <b-form inline class="mt-4 ml-4" @submit.stop.prevent>
      <b-button-group class="mr-4">
        <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{$t('message.btn_prevpage')}}</b-button>
        <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
        <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{$t('message.btn_nextpage')}}</b-button>
      </b-button-group>
      <b-radio-group
        buttons
        v-model="cbktype"
        class="mr-4"
        :options="cbktypes"
        @change="cbkchange"
        button-variant="outline-primary"
      ></b-radio-group>
      <b-input-group class="col-7 col-sm-6 col-md-6 col-lg-5 col-xl-4">
            <b-form-input placeholder="起始桩号" v-model="cidstart" @keyup.enter="dofilter"></b-form-input>
            <b-form-input placeholder="结束桩号" v-model="cidend" @keyup.enter="dofilter"></b-form-input>
            <b-input-group-append>
              <b-button variant="info" @click="dofilter">过滤</b-button>
            </b-input-group-append>
      </b-input-group>
    </b-form>
    <div class="pl-5 pr-5 mt-5 mb-5 d-none d-lg-block">
      <b-card class="xnshadow">
        <b-card-text>
          点击"分配日期"可查看充电桩限流设置<br/>
          保存限流设置需小牛公司审核，开启大电流需要满足条件：<br/>
          1.充电桩地线工作良好<br/>
          2.充电桩安装规范，线径足额<br/>
          3.至少四路零线输出<br/>
          4.清晰完整的现场照片提供给小牛公司审核<br/>
          5.将12个端口全部调整为大电流将不会被审核通过<br/>
          默认最大电流为4.5A,标准电压220V，最大功率为220*4.5=990瓦。长时间异常工作（地线）最大功率将会调低。<br/>
          可调最大电流为13A，即2860瓦。建议最大调整值为11.4即2500瓦。<br/>
          后台计费按900~1300瓦，1300~1700瓦，1700~2100瓦，2100以上四个标准执行。
        </b-card-text>
      </b-card>
    </div>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')">{{modalmsg}}</b-modal>
  </b-container>
</template>

<style>
  .tooltip-inner{text-align:left;}
</style>
<script>
  import { QRSITE } from '@/config'
  export default {
    name: 'mystore',
    mounted() {
      this.cbktype = parseInt(localStorage.getItem('mystcbk')) || 0;
      this.fetchData();
    },
    data() {
      return {
        isloading: false,
        modalshow:false,
        modalmsg: '',
        maskenabled: false,
        bigheadimgurl: '',
        admnodft:false,
        cbktype:0,
        sort: 0,
        updown:1,
        showid:0,//0=正常模式 1功率修改
        workingid: -1,
        cidstart: '',
        cidend: '',
        resetflags:[this.$t('message.ref0'),this.$t('message.ref1'),this.$t('message.ref2'),this.$t('message.ref3'),this.$t('message.ref4'),this.$t('message.ref5'),this.$t('message.ref6'),this.$t('message.ref7')],
        cbktypes:[{text:this.$t('message.cbk0'),value:0},{text:this.$t('message.cbk1'),value:1},{text:this.$t('message.cbk2'),value:2}],
        items: [],
        pageid:0,
        newpagestart:[]
      };
    },
    methods: {
      fetchprev() {
        this.pageid = this.pageid-1;
        this.fetchData();
      },
      fetchPage(page) {
        this.pageid = page;
        this.fetchData();
      },
      fetchNext() {
        this.pageid = this.pageid+1;
        this.fetchData();
      },
      cbkchange($event) {
        this.cbktype = $event;
        localStorage.setItem('mystcbk',''+$event);
        this.pageid = 0;
        this.newpagestart = [];
        this.fetchData();
      },
      changesort(id) {
        if (this.sort!=id) {
          this.sort = id;
          this.pageid = 0;
          this.newpagestart = [];
          this.fetchData();
        }
      },
      doupdown(id) {
        if (this.updown==1) {
          this.updown = 0;
        } else {
          this.updown = 1;
        }
        if (this.sort!=id) {
          this.sort = id;
          this.updown = 1;
        }
        this.pageid = 0;
        this.newpagestart = [];
        this.fetchData();
      },
      dofilter() {
        this.sort = 0;
        this.pageid = 0;
        this.fetchData();
      },
      exshowid() {
        this.workingid = -1;
        this.showid = 1-this.showid;
      },
      setme(index) {
        this.workingid = index;
      },
      fetchData() {
        this.isloading = true;
        let user = JSON.parse(localStorage.getItem('user'));
        let dftapp = localStorage.getItem('dftapp');
        if (user.isadmin && !dftapp) {
          this.admnodft = true;
        } else {
          this.admnodft = false;
        }
        let w = window.innerWidth;
        let h = window.innerHeight;
        let token = localStorage.getItem('token');
        let theapi = '/mystore?tm='+new Date().getTime()+'&token='+token+'&pagesize='+this.$pagesize+'&sort='+this.sort+'&ud='+this.updown+'&cbk='+this.cbktype+'&cidst='+this.cidstart+'&ciden='+this.cidend+'&w='+w+'&h='+h;
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          theapi = theapi + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        this.axios.get(theapi).then(axresp => {
          this.isloading = false;
          if (axresp.status==200) {
            let axdata = axresp.data;
            this.items = axdata.Items;
            if (axdata.LastEvaluatedKey) {
              if (this.newpagestart.length<=this.pageid) {
                this.newpagestart.push(axdata.LastEvaluatedKey);
              } else {
                this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
              }
            }
          } else {
            this.items = [];
          }
        });
      },
      stoclearsiteid(itm) {
        let token = localStorage.getItem('token');
        let theapi = '/stoclearsiteid?tm=' + new Date().getTime() + '&token=' + token + '&mac=' + itm.mac;
        this.axios.get(theapi).then(axresp => {
          if (axresp.status == 200) {
            let axdata = axresp.data;
            if (axdata.rc>0) {
              this.fetchData();
            } else {
              this.modalmsg = axdata.rm;
              this.modalshow = true;
            }
          }
        });
      },
      stoclearopenid(itm) {
        let token = localStorage.getItem('token');
        let theapi = '/stoclearopenid?tm=' + new Date().getTime() + '&token=' + token + '&mac=' + itm.mac;
        this.axios.get(theapi).then(axresp => {
          if (axresp.status == 200) {
            let axdata = axresp.data;
            if (axdata.rc>0) {
              this.fetchData();
            } else {
              this.modalmsg = axdata.rm;
              this.modalshow = true;
            }
          }
        });
      },
      storeqrcode(itm) {
        if (itm.chargerid==0) {
          this.modalmsg = '设备未开机。 在本页最下面配置开机分配的APP和编号,然后开机,设备将分配到对应编号和应用。';
          this.modalshow = true;
        } else if (itm.hasqr){
          let cids = ''+itm.chargerid;
          let imgurl = QRSITE + '/qr/' + cids.substr(0,2) + '/' + cids.substr(2,2) + '/' + cids + '.jpeg';
          this.bigheadimgurl = imgurl;
          this.maskenabled = true;
        } else {
          let that = this;
          let token = localStorage.getItem('token');
          let genqrapi = '/admstoqrcode?tm='+new Date().getTime()+'&token='+token+'&mac='+itm.mac;
          this.axios.get(genqrapi).then(axresp => {
            if (axresp.data.rc>0) {
              itm.hasqr = 1;
              let cids = ''+itm.chargerid;
              let imgurl = QRSITE + '/qr/' + cids.substr(0,2) + '/' + cids.substr(2,2) + '/' + cids + '.jpeg';
              that.bigheadimgurl = imgurl;
              that.maskenabled = true;
            }
          });
        }
      },
      closemask() {
        this.maskenabled = false;
      },
      saveme(itm) {
        let checkok = true;
        let iarr = itm.imax;
        for (let i=0;i<iarr.length;i++) {
          iarr[i] = Number(iarr[i]);
        }
        for (let i=0;i<iarr.length;i++) {
            if (iarr[i]>0 && iarr[i]<2) {
              this.modalmsg = '端口'+(i+1)+'的电流限制过小,应该大于2A';
              this.modalshow = true;
              checkok = false;
              break;
            }
            if (iarr.length==12 && iarr[i]>13) {
              this.modalmsg = '端口'+(i+1)+'的电流限制太大,不应该超过13A';
              this.modalshow = true;
              checkok = false;
              break;
            }
            if (iarr.length==2 && iarr[i]>35) {
              this.modalmsg = '端口'+(i+1)+'的电流限制太大,不应该超过35A';
              this.modalshow = true;
              checkok = false;
              break;
            }
        }
        if (checkok) {
          this.workingid = -1;
          let token = localStorage.getItem('token');
          let theapi = '/stoilimit?tm=' + new Date().getTime() + '&token=' + token + '&mac=' + itm.mac+'&imax='+iarr.join(',');
          this.axios.get(theapi).then(axresp => {
            if (axresp.status == 200) {
              let axdata = axresp.data;
              if (axdata.rc>0) {
                this.fetchData();
              } else {
                this.modalmsg = axdata.rm;
                this.modalshow = true;
              }
            }
          });
        }
      },
      gotodevice(itm) {
        let cidstart = itm.chargerid-1;
        let cidend = itm.chargerid+1;
        if (itm.siteid==0) {
          window.location.href = '/#/device' + this.cbktype+'?siteid=0&cidstart='+cidstart+'&cidend='+cidend;
        } else {
          window.location.href = '/#/device' + this.cbktype+'?cidstart='+cidstart+'&cidend='+cidend;
        }
      },
    }
  }
</script>
